import { AxiosResponse } from 'axios';

export const handleFilePicker = (
  apiInstance: any, 
  cb: (url: string, meta: { title: string }) => void
) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.addEventListener('change', (e) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    apiInstance.post('/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<{ location: string }>) => {
      cb(response.data.location, { title: file.name });
    })
    .catch((error: unknown) => {
      console.error('Error uploading file:', error);
      alert('Error uploading file, open the console to get more information!');
    });
  });

  input.click();
};
