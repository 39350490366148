// @ts-nocheck
export const editorInit = {
  height: 500,
  menubar: false,
  plugins: ['link', 'emoticons', 'table', 'lists', 'code', 'visualblocks', 'image'],
  paste_as_text: true,
  toolbar: [
    { name: 'history', items: [ 'undo', 'redo', 'image' ] },
    { name: 'styles', items: [ 'styles', 'visualblocks' ] },
    { name: 'formatting', items: [ 'removeformat', 'bold', 'italic', 'link', 'emoticons', 'table', 'numlist', 'bullist', 'code' ] },
    { name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ] },
    { name: 'indentation', items: [ 'outdent', 'indent' ] },
  ],
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .block-placeholder { display: inline-block; padding: 16px; width: 100%; background-color: #ba68c8; color: #fff; text-align: center; text-transform: uppercase; border-radius: 4px; box-sizing: border-box }',
  noneditable_class: 'block-placeholder',
  table_default_attributes: {
    class: 'table'
  },
  link_class_list: [
    {title: 'None', value: ''},
    {title: 'Green button', value: 'button button--green'},
    {title: 'Light button', value: 'button button--light'},
  ],
  visualblocks_default_state: false,
  style_formats: [
    { title: 'Headings', items: [
      { title: 'Heading 1', format: 'h1' },
      { title: 'Heading 2', format: 'h2' },
      { title: 'Heading 3', format: 'h3' },
      { title: 'Heading 4', format: 'h4' },
      { title: 'Heading 5', format: 'h5' },
      { title: 'Heading 6', format: 'h6' }
    ]},
    { title: 'Inline', items: [
      { title: 'Bold', format: 'bold' },
      { title: 'Italic', format: 'italic' },
      { title: 'Underline', format: 'underline' },
      { title: 'Strikethrough', format: 'strikethrough' },
      { title: 'Superscript', format: 'superscript' },
      { title: 'Subscript', format: 'subscript' },
      { title: 'Code', format: 'code' }
    ]},
    { title: 'Blocks', items: [
      { title: 'Paragraph', format: 'p' },
      { title: 'Blockquote', format: 'blockquote' },
      { title: 'Div', format: 'div' },
      { title: 'Pre', format: 'pre' }
    ]},
    { title: 'Align', items: [
      { title: 'Left', format: 'alignleft' },
      { title: 'Center', format: 'aligncenter' },
      { title: 'Right', format: 'alignright' },
      { title: 'Justify', format: 'alignjustify' }
    ]},
    { title: 'Containers', items: [
      { title: 'section', block: 'section', wrapper: true, merge_siblings: false },
      { title: 'article', block: 'article', wrapper: true, merge_siblings: false },
      { title: 'blockquote', block: 'blockquote', wrapper: true },
      { title: 'hgroup', block: 'hgroup', wrapper: true },
      { title: 'aside', block: 'aside', wrapper: true },
      { title: 'figure', block: 'figure', wrapper: true }
    ] },
  ],
  image_title: true,
  automatic_uploads: true,
  file_picker_types: 'image',
  image_caption: true,
  // file_picker_callback: (cb, value, meta) => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');

  //   input.addEventListener('change', (e) => {
  //     const file = e.target.files[0];

  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => {
  //       /*
  //         Note: Now we need to register the blob in TinyMCEs image blob
  //         registry. In the next release this part hopefully won't be
  //         necessary, as we are looking to handle it internally.
  //       */
  //       const id = 'blobid' + (new Date()).getTime();
  //       const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
  //       const base64 = reader.result.split(',')[1];
  //       const blobInfo = blobCache.create(id, file, base64);
  //       blobCache.add(blobInfo);

  //       /* call the callback and populate the Title field with the file name */
  //       cb(blobInfo.blobUri(), { title: file.name });
  //     });
  //     reader.readAsDataURL(file);
  //   });

  //   input.click();
  // },
  init_instance_callback: function (editor) {
    editor.on('KeyDown', function (e) {
      if(e.keyCode === 27) {
        let editor = tinyMCE.activeEditor
        const dom = editor.dom
        const parentBlock = tinyMCE.activeEditor.selection.getSelectedBlocks()[0]
        const containerBlock = parentBlock.parentNode.nodeName === 'BODY' ? dom.getParent(parentBlock, dom.isBlock) : dom.getParent(parentBlock.parentNode, dom.isBlock)
        let newBlock = tinyMCE.activeEditor.dom.create('p')
        newBlock.innerHTML = '<br data-mce-bogus="1">';
        dom.insertAfter(newBlock, containerBlock)
        let rng = dom.createRng();
        newBlock.normalize();
        rng.setStart(newBlock, 0);
        rng.setEnd(newBlock, 0);
        editor.selection.setRng(rng);
      }
    });
  },
  valid_elements : '*[*]',
  extended_valid_elements: 'img[class=image|src|alt=|title|width=|height=|loading=lazy|style]',
}
